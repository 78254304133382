<template>
  <div class="Receivingprintdetails">
    <div class="nav-title">
      订单的结算金额未减平台服务费，平台服务费在结算单统一扣减
    </div>
    <div class="auto-table-flex">
      <div class="ctns">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :header-cell-style="{ color: '#333333', fontSize: '16px' }"
        >
          <el-table-column type="index" width="100" align="center" label="序号">
          </el-table-column>
          <el-table-column prop="address" align="center" label="订单编号">
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.order_no) }}
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="支付时间">
            <template slot-scope="scope">
              {{ $empty.time(scope.row.order_pay_time) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            align="center"
            label="服务费支付金额(元)"
          >
            <template slot-scope="scope">
              {{
                tool.toDecimal2(
                  (scope.row.purchase_amount * 10000) / 100 / 10000
                )
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            align="center"
            label="售后退费金额(元)"
          >
            <template slot-scope="scope">
              {{
                tool.toDecimal2(
                  (scope.row.aftersale_amount * 10000) / 100 / 10000
                )
              }}
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="结算比例(%)">
            <template slot-scope="scope">
              {{ scope.row.liquidate_rate }}
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="结算金额(元)">
            <template slot-scope="scope">
              {{
                tool.toDecimal2(
                  (scope.row.liquidate_amount * 10000) / 10000 / 10000
                )
              }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="btn">
      <el-button @click="fanhui">返回</el-button>
    </div>
  </div>
</template>
<script>
import { BASE } from "../../api/index";
export default {
  name: "Receivingprintdetails",
  components: {},
  data() {
    return {
      tableData: [],
      info: "",
    };
  },
  created() {
    this.hqinfo();
  },
  methods: {
    hqinfo() {
      this.$api.general
        .purchaseDetail({ id: this.$route.query.id })
        .then((res) => {
          console.log(res, "获取数据");
          this.tableData = res.data;
        });
    },
    fanhui() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.Receivingprintdetails {
  height: 100%;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-sizing: border-box;
  .nav-title {
    padding-top: 20px;
    padding-left: 15px;
    font-size: 15px;
    font-weight: 550;
  }
  .auto-table-flex {
    flex-grow: 1;
    overflow-y: auto;
    .ctns {
      padding: 23px 12px;
      box-sizing: border-box;
    }
  }
  .toptli {
    display: flex;
    .flexbox {
      text-align: center;
      img {
        width: 109px;
        height: 109px;
      }
      div {
        margin-top: 8px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
  }
  .btn {
    border-top: 1px solid #e8e8e8;
    display: flex;
    padding: 14px 0;

    box-sizing: border-box;
    justify-content: center;
  }

  .divtitle {
    flex: 1;
    .titletab {
      margin-bottom: 31px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .div {
      display: flex;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: 24px;
      .its {
        flex: 1;
      }
    }
  }

  /**
    改变边框颜色
     */
  /deep/ .el-table--border,
  .el-table--group {
    border: 1px solid #666 !important;
  }
  /**
    改变表格内竖线颜色
     */
  /deep/ .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: 1px solid #666 !important;
  }
  /**
    改变表格内行线颜色
     */
  /deep/ .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid #666 !important;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #666 !important;
  }

  /deep/ .el-table thead tr th {
    border-color: #666;
  }
  .rt {
    border-right: 1px solid #666666 !important;
  }
}
</style>
